import React, { useState, useEffect } from "react"
import classNames from "classnames"
import NavbarItem from "./NavbarItem"
import Row from "../Row"
import Column from "../Column"
import Icon from "../Icon"
import Wrapper from "../Wrapper"
import Link from "../Link"
import styles from "./index.module.scss"

const links = [
  {
    name: "About Us",
    to: "/about",
  },
  {
    name: "Our Services",
    to: "/services",
  },
  // { name: "Volunteer", to: "/volunteer" },
  {
    name: "Links and Resources",
    to: "/resources",
  },
  {
    name: "Contact Us",
    to: "/contact",
  },
]

const NavBar = () => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    document.body.style.overflow = open ? "hidden" : "auto"
  }, [open])
  return (
    <header className={styles.navContainer}>
      <Wrapper component="div">
        <nav className={styles.navigation}>
          <Row spaceBetween middle>
            <Column mobile={2} desktop={2}>
              <Link to="/" ariaLabel="Link to home page">
                <Icon svgId="logo" className={styles.logo} />
              </Link>
            </Column>
            <Column mobile={6} desktop={14}>
              <Row middle>
                <Column mobile={4} desktop={13}>
                  <ul
                    className={classNames(
                      styles.menuList,
                      open && styles.menuListOpen
                    )}
                  >
                    {links.map((link, i) => (
                      <li key={`navItem-${i}`} className={styles.menuListItem}>
                        <NavbarItem to={link.to} name={link.name} />
                      </li>
                    ))}
                    <li>
                      <Link
                        className={styles.exitLinkMobile}
                        variant="exit"
                        iconId="exit"
                        onClick={() =>
                          window.location.replace("https://google.ca")
                        }
                      >
                        Exit Site
                      </Link>
                    </li>
                  </ul>
                </Column>
                <Column mobile={8} desktop={3} className={styles.ctaContainer}>
                  <Link variant="primary" to="/contact">
                    Get help
                  </Link>
                  <button
                    type="button"
                    className={styles.menuButton}
                    onClick={() => setOpen(!open)}
                  >
                    Menu
                    <Icon
                      svgId={open ? "mobileMenuClose" : "mobileMenu"}
                      width="15"
                      height="15"
                      className={styles.menuIcon}
                    />
                  </button>
                </Column>
              </Row>
            </Column>
          </Row>
          <Link
            variant="exit"
            iconId="exit"
            className={styles.exitLinkDesktop}
            onClick={() => window.location.replace("https://google.ca")}
          >
            Exit Site
          </Link>
        </nav>
      </Wrapper>
      <div className={classNames(styles.overlay, open && styles.overlayOpen)} />
    </header>
  )
}

export default NavBar
