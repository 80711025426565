import React from "react"
import classNames from "classnames"

import styles from "./index.module.scss"
type PropsType = {
  children?: React.ReactNode
  mobile: number
  desktop: number
  noMargin?: boolean
  className?: string
}

const MOBILE_COLUMN_COUNT = 8
const DESKTOP_COLUMN_COUNT = 16

function getColumnNumber(column: number, breakpoint: string): number {
  if (breakpoint === "mobile") {
    if (column > MOBILE_COLUMN_COUNT) return MOBILE_COLUMN_COUNT
  }

  if (breakpoint === "desktop") {
    if (column > DESKTOP_COLUMN_COUNT) return DESKTOP_COLUMN_COUNT
  }
  if (column < 1) return 1
  return column
}

const Column = ({
  mobile,
  desktop,
  className,
  noMargin,
  children,
}: PropsType) => (
  <div
    className={classNames(className, styles.column, {
      [styles[`columnMobile${mobile}`]]:
        getColumnNumber(mobile, "mobile") === mobile,
      [styles[`columnDesktop${desktop}`]]:
        getColumnNumber(desktop, "desktop") === desktop,
      [styles.columnNoMargin]: noMargin,
    })}
  >
    {children}
  </div>
)

export default Column
