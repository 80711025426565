/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import NavBar from "./navbar/navbar"
import Footer from "./Footer"
import IconDefinitions from "./Icon/IconDefinitions"

type Props = {
  children: React.ReactNode
  className?: string
}

const Layout = ({ children }: Props) => {
  return (
    <IconDefinitions>
      <NavBar />
      <main>{children}</main>
      <Footer />
    </IconDefinitions>
  )
}

export default Layout
