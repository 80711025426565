import React from "react"
import classNames from "classnames"

import styles from "./index.module.scss"

type PropsType = {
  center?: boolean
  spaceBetween?: boolean
  middle?: boolean
  children: React.ReactNode
  role?: string
  className?: string
}

const Row = ({
  center,
  spaceBetween,
  middle,
  role,
  className,
  children,
}: PropsType) => {
  return (
    <div
      role={role}
      className={classNames(className, styles.row, {
        [styles.rowCenter]: center,
        [styles.rowSpaceBetween]: spaceBetween,
        [styles.rowMiddle]: middle,
      })}
    >
      {children}
    </div>
  )
}

export default Row
