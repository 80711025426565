/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"

const SEO = ({ title }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title="Ah-shawah-bin Victim Support Services"
      titleTemplate={`%s | ${title}`}
      meta={[
        {
          name: "description",
          content: "Ah-shawah-bin Victim Support Services",
        },
        {
          property: "og:title",
          content: "Ah-shawah-bin Victim Support Services",
        },
        {
          property: "og:description",
          content: "Ah-shawah-bin Victim Support Services",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:creator",
          content: "Ah-shawah-bin Victim Support Services",
        },
        {
          name: "twitter:title",
          content: "Ah-shawah-bin Victim Support Services",
        },
        {
          name: "twitter:description",
          content: "Ah-shawah-bin Victim Support Services",
        },
      ]}
    />
  )
}

export default SEO
