import React from "react"
import Img from "gatsby-image"
import classNames from "classnames"
import { useStaticQuery, graphql } from "gatsby"

import Column from "../Column"
import Row from "../Row"
import Wrapper from "../Wrapper"

import styles from "./index.module.scss"
import { BodyExtraSmall, H2, H3 } from "../Typography"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      ontario: file(relativePath: { eq: "ontario.png" }) {
        childImageSharp {
          fixed(width: 120, height: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <footer className={styles.footer}>
      <Wrapper>
        <Row center>
          <Column mobile={8} desktop={14}>
            <Row spaceBetween>
              <Column mobile={8} desktop={6}>
                <H2
                  component="h2"
                  className={classNames(styles.footerTitle, styles.footerText)}
                >
                  Ah-shawah-bin Sioux Lookout / Lac Seul Victim Support Services
                </H2>
                <H3
                  className={classNames(
                    styles.footerSubtitle,
                    styles.footerText
                  )}
                >
                  Contact Us
                </H3>
                <Row>
                  <Column mobile={4} desktop={8}>
                    <BodyExtraSmall>
                      #429, HWY 516
                      <br />
                      P.O. Box 1327
                      <br />
                      Sioux Lookout, ON
                      <br />
                      P8T 1B8
                    </BodyExtraSmall>
                  </Column>
                  <Column mobile={4} desktop={8}>
                    <BodyExtraSmall>
                      Phone: 807-737-1700
                      <br />
                      Toll Free: 1-888-949-9300
                      <br />
                      Fax: 807-737-1701
                    </BodyExtraSmall>
                  </Column>
                </Row>
              </Column>
              <Column mobile={8} desktop={8} className={styles.columnRight}>
                <BodyExtraSmall>
                  The views expressed in this publication are the views of the
                  author/organization and do not necessarily reflect those of
                  the Government of Ontario.
                </BodyExtraSmall>
                <div className={styles.disclaimerContainer}>
                  <BodyExtraSmall>
                    Member of the{" "}
                    <strong>
                      Ontario Network of Victim Service Providers{" "}
                    </strong>
                  </BodyExtraSmall>
                  <div className={styles.ontarioLogoContainer}>
                    <BodyExtraSmall>Funding provided by </BodyExtraSmall>
                    <Img
                      className={styles.ontarioLogo}
                      fixed={data.ontario.childImageSharp.fixed}
                      alt="Logo of the province of ontario"
                    />
                  </div>
                </div>
              </Column>
            </Row>
          </Column>
        </Row>
      </Wrapper>
    </footer>
  )
}

export default Footer
