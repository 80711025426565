import React from "react"
import classNames from "classnames"
import styles from "./index.module.scss"

type Props = {
  component?: "div" | "main" | "header" | "section"
  children?: React.ReactNode
  className?: string
}

const Wrapper = ({
  component: Component = "div",
  className,
  children,
}: Props) => (
  <Component className={classNames(className, styles.wrapper)}>
    {children}
  </Component>
)

export default Wrapper
