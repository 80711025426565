import React from "react";
import classNames from "classnames";
import styles from "./index.module.scss";

type PropsType = {
  svgId: string;
  height?: string;
  width?: string;
  hideInScreenReader?: boolean;
  className?: string;
  ariaLabel?: string;
};

const Icon = ({
  svgId,
  height,
  width,
  hideInScreenReader,
  className,
  ariaLabel,
}: PropsType) => (
  <svg
    width={width}
    height={height}
    className={classNames(className, styles.icon)}
    aria-hidden={hideInScreenReader}
    aria-label={ariaLabel}
  >
    <use xlinkHref={`#${svgId}`} />
  </svg>
);

export default Icon;
