import React from "react"
import Link from "../Link"
import Dropdown from "./Dropdown"

type Props = {
  to?: string
  name: string
  dropdowns?: Array<{
    name: string
    to: string
  }>
}
const NavbarItem = ({ to, name, dropdowns }: Props) => {
  return dropdowns ? (
    <Dropdown dropdowns={dropdowns} name={name} />
  ) : (
    <Link to={to} variant="nav">
      {name}
    </Link>
  )
}

export default NavbarItem
