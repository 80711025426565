import React, { useState, useEffect, useRef } from "react"
import Link from "../Link"

import styles from "./index.module.scss"

type Props = {
  name: string
  dropdowns: Array<{
    name: string
    to: string
  }>
}

const Dropdown = ({ name, dropdowns }: Props) => {
  const [expanded, setExpanded] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const eventTarget = event.target as Node
      if (
        wrapperRef &&
        wrapperRef.current &&
        !wrapperRef.current.contains(eventTarget)
      ) {
        setExpanded(false)
      }
    }
    const handleEscPress = (event: KeyboardEvent) =>
      event.key === "Escape" && setExpanded(false)
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    document.addEventListener("keydown", handleEscPress)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
      document.removeEventListener("keydown", handleEscPress)
    }
  }, [wrapperRef])

  return (
    <div ref={wrapperRef}>
      <Link
        variant="nav"
        onClick={() => setExpanded(!expanded)}
        iconId="chevronDown"
        aria-expanded={expanded}
        aria-controls="dropdown-menu-list"
        aria-haspopup="true"
      >
        {name}
      </Link>
      {expanded && (
        <ul
          className={styles.dropdownList}
          aria-expanded={true}
          id="dropdown-menu-list"
          role="menu"
        >
          {dropdowns.map((item, i) => (
            <li
              key={`dropdown-${i}`}
              role="menuitem"
              onBlur={() => i === dropdowns.length - 1 && setExpanded(false)}
            >
              <Link to={item.to} variant="nav" className={styles.dropdownItem}>
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default Dropdown
