import React from "react"
import { Link as GatsbyLink } from "gatsby"
import classNames from "classnames"
import Icon from "../Icon"
import styles from "./index.module.scss"

type PropsType = {
  to?: string
  children: React.ReactNode
  className?: string
  variant?: "primary" | "exit" | "nav" | "secondary"
  onClick?: () => void
  iconId?: string
  download?: boolean
  ariaLabel: string
}

const Link = ({
  to,
  variant,
  children,
  className,
  onClick,
  iconId,
  download,
  ariaLabel,
}: PropsType) => {
  const isExternal = to && to.split("/")[0] !== ""

  const cx = classNames(className, styles.link, variant && styles[variant])
  const Component = to && !isExternal ? GatsbyLink : "button"

  return isExternal || download ? (
    <a
      href={to}
      className={cx}
      target="_blank"
      rel="noreferrer"
      download={download}
      aria-label={ariaLabel}
    >
      <span className={styles.linkText}>{children}</span>
      {iconId && (
        <Icon
          svgId={iconId}
          width="15px"
          height="15px"
          className={styles.icon}
        />
      )}
    </a>
  ) : (
    <Component
      to={to || ""}
      className={cx}
      onClick={onClick}
      aria-label={ariaLabel}
      aria-current="page"
      activeClassName={variant === "nav" ? styles.navActive : undefined}
    >
      <span className={styles.linkText}>{children}</span>
      {iconId && (
        <Icon
          svgId={iconId}
          width="15px"
          height="15px"
          className={styles.icon}
        />
      )}
    </Component>
  )
}

export default Link
