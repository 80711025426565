import React from "react"
import classNames from "classnames"

import styles from "./index.module.scss"

type TextPropsType = {
  children?: React.ReactNode | string | number | undefined
  className?: string
  fontWeight?: "regular" | "bold"
  id?: string
  "aria-labelledby"?: string
}

type HeadingProps = TextPropsType & {
  component?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
}

const classes = ({
  className,
  style,
  fontWeight,
}: TextPropsType & { style: string }) =>
  classNames(className, style, {
    [styles.FontWeightRegular]: fontWeight === "regular",
    [styles.FontWeightBold]: fontWeight === "bold",
  })

export const H1 = ({
  children,
  className,
  component: Component = "h1",
  fontWeight,
  ...rest
}: HeadingProps) => (
  <Component
    className={classes({ className, style: styles.H1, fontWeight })}
    {...rest}
  >
    {children}
  </Component>
)

export const H2 = ({
  children,
  className,
  component: Component = "h2",
  fontWeight,
  ...rest
}: HeadingProps) => (
  <Component
    className={classes({ className, style: styles.H2, fontWeight })}
    {...rest}
  >
    {children}
  </Component>
)

export const H3 = ({
  children,
  className,
  component: Component = "h3",
  fontWeight,
  ...rest
}: HeadingProps) => (
  <Component
    className={classes({ className, style: styles.H3, fontWeight })}
    {...rest}
  >
    {children}
  </Component>
)

export const H4 = ({
  children,
  className,
  component: Component = "h4",
  fontWeight,
  ...rest
}: HeadingProps) => (
  <Component
    className={classes({ className, style: styles.H4, fontWeight })}
    {...rest}
  >
    {children}
  </Component>
)

export const BodyLarge = ({
  children,
  className,
  fontWeight,
  ...rest
}: TextPropsType) => (
  <p
    className={classes({ className, style: styles.BodyLarge, fontWeight })}
    {...rest}
  >
    {children}
  </p>
)

export const Body = ({
  children,
  className,
  fontWeight,
  ...rest
}: TextPropsType) => (
  <p
    className={classes({ className, style: styles.Body, fontWeight })}
    {...rest}
  >
    {children}
  </p>
)

export const BodySmall = ({
  children,
  className,
  fontWeight,
  ...rest
}: TextPropsType) => (
  <p
    className={classes({ className, style: styles.BodySmall, fontWeight })}
    {...rest}
  >
    {children}
  </p>
)

export const BodyExtraSmall = ({
  children,
  className,
  fontWeight,
  ...rest
}: TextPropsType) => (
  <p
    className={classes({
      className,
      style: styles.BodyExtraSmall,
      fontWeight,
    })}
    {...rest}
  >
    {children}
  </p>
)
